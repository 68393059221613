import getImages from './getImages';
let counter = 0;
let noMoreImages = false;
const images = getImages();

export default function() {
    if(noMoreImages) {
        return [];
    }
    let startPointer = counter;
    let endPointer;
    if(counter + 15 <= images.length) {
        endPointer = counter + 15;
    } else {
        endPointer = images.length;
        noMoreImages = true;
    }

    counter = endPointer;
    
    return images.slice(startPointer, endPointer);
}