import React, { Component } from 'react';
import './App.css';
import getTwentyFiveImages from './getTwentyFiveImages';
import stringifyImages from './stringifyImages';
import ReactDOMServer from 'react-dom/server';
const $ = window.$ = window.jQuery

class App extends Component {
  componentDidMount() {
    const grid = $('.grid');
    grid.packery({
      itemSelector: '.grid-item'
    });

    grid.imagesLoaded().progress( function() {
      grid.packery();
    });

    $(window).scroll(function () {
      if ($(window).scrollTop() + $(window).height() === $(document).height()) {
        var moreImages = $(ReactDOMServer.renderToStaticMarkup(stringifyImages(getTwentyFiveImages())));

        grid.append(moreImages);
        setTimeout(function(){
          grid.packery('appended', moreImages);
          grid.imagesLoaded().progress( function() {
            grid.packery();
          });
        }, 1000);
      }
    });
  }
  render() {
    return (
      <div className="grid" >
        {stringifyImages(getTwentyFiveImages())}
      </div>
    );
  }
}

export default App;
