import shuffle from './shuffle';
import unmodifiedImages from './images';

let fetched = false;
let images = [];

export default function () {
    if(!fetched) {
        images = shuffle(unmodifiedImages);
        fetched = true;
    }
    return images;
    //https://s3.amazonaws.com/jon-and-sara-wedding-website-photos/
}