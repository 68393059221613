import React from 'react';

export default function(images) {
    let retVal = [];
    for(let i = 0; i < images.length; i++) {
        let imgUrl = "https://s3.amazonaws.com/jon-and-sara-wedding-website-photos/" + images[i];
        retVal.push(
            <div className="grid-item">
                <img src={imgUrl} />
            </div>
        );
    }
    return retVal;
}